import { PageProps } from 'gatsby';
import { TaskData } from 'packages/innedit';
import { parse } from 'query-string';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import task from '~/params/task.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceTasksCreate: FC<PageProps & EspaceProps & UserProps> =
  function ({ espace, location: { search }, user }) {
    const model = new TaskData({ espace, espaceId: espace.id, params: task });

    let params;
    if (search) {
      params = parse(search);
    }

    return (
      <TemplateEspace espace={espace} user={user}>
        <CMSView>
          <Form
            docId={model.getNewDocId()}
            initializeData={params}
            itemPathnamePrefix={`/espaces/${espace.id}/tasks/`}
            model={model}
            name="task"
            type="create"
          />
        </CMSView>
      </TemplateEspace>
    );
  };

export default requireEspace(PageEspaceTasksCreate);
